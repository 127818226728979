<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="12">
          <ecommerce-statistics :data="datacom.statisticsItems" />        
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Goal Overview Card -->
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="datacom.goalOverview" />
      </b-col>
      <!--/ Goal Overview Card -->
      <b-col
        lg="4"
        sm="6"
      >
        <apex-data-bar-chart-iqoa />
      </b-col>      
      <b-col
        lg="4"
        sm="6"
      >
        <analytics-timeline :data="datacom.timeline" />    
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <apex-data-bar-chart />
      </b-col>
      <b-col lg="6">
        <apex-data-bar-chart-maintenance />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <apex-donut-chart />
      </b-col>
      <b-col lg="8">
        <ecommerce-company-table :table-data="datacom.companyTable"/>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import ApexDataBarChart from './ApexDataBarChart.vue'
import ApexDataBarChartMaintenance from './ApexDataBarChartMaintenance.vue'
import ApexDataBarChartIqoa from './ApexDataBarChartIqoa.vue'
import ApexDonutChart from './ApexDonutChart.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'


export default {
  components: {
    BRow,
    BCol,
    AnalyticsTimeline,
    EcommerceGoalOverview,
    ApexDataBarChart,
    ApexDataBarChartMaintenance,
    EcommerceStatistics,
    ApexDataBarChartIqoa,
    ApexDonutChart,
    EcommerceCompanyTable,
    StatisticCardWithAreaChart,
  },
  data() {
    return {
      data: {},
      datacom: {},
    }
  },
  created() {
    let userData = getUserData()
    if (userData.role === 'inspecteur') {
      this.$router.push({path: '/dashboard/inspecteur'})
    } else if (userData.role === 'mainteneur')
     this.$router.push({path: '/dashboard/mainteneur'})

    // data
    this.$http.get('/analytics/data')
      .then(response => { 
        this.data = response.data        
        })
      // data
    this.$http.get('/moa/data')
      .then(response => {
        this.datacom = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })      
  },
  methods: {
    kFormatter,
  },
}
</script>
