<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-card-header>
      <!-- title and legend -->
      <b-card-title class="mb-50">
        Liste des désordres les plus critiques
      </b-card-title>
      <!--/ title and legend -->
    </b-card-header>
    <b-card-body>  
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(type)="data">
        <div class="d-flex align-items-center">
          <div>
            <div :class="data.item.color">
              {{ data.item.type }}
            </div>
          </div>
        </div>
      </template>

      <!-- company -->
      <template #cell(s)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="text-danger font-weight-bold">
              {{ data.item.s }}
            </div>
          </div>
        </div>
      </template>      

      <!-- category -->
      <template #cell(libelle)="data">
        <div class="d-flex align-items-center">
          <div>
            <div  :class="data.item.color">
              {{ data.item.libelle }}
            </div>
          </div>
        </div>
      </template>

      <!-- views -->
      <template #cell(created)="data">
        <div class="d-flex flex-column">
          <span :class="data.item.color">{{ data.item.created }}</span>
        </div>
      </template>

      <!-- revenue -->
      <template #cell(iqoa)="data">
        <div class="d-flex flex-column">
          <span  :class="data.item.color">{{ data.item.iqoa }}</span>
        </div>
      </template>

      <!-- sales -->
      <template #cell(maintenance)="data">
        <div class="d-flex flex-column">
          <span  :class="data.item.color">{{ data.item.maintenance }}</span>
        </div>
      </template>
    </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,BCardHeader,BCardBody,BCardTitle
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'libelle', label: 'Denomination' },
        { key: 'created', label: 'Créé le' },
        { key: 'iqoa', label: 'IQOA' },
       { key: 's', label: '' },        
        { key: 'maintenance', label: 'Maintenance associée' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
